import {ControllerFlowAPI, IHttpClient} from '@wix/yoshi-flow-editor'
import {listReservationLocations} from '@wix/ambassador-table-reservations-v1-reservation-location/http'
import {
  CursorPaging,
  ReservationLocation,
} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {getDataFromMemoryCache, setDataToMemoryCache} from '../utils/dataCapsule'
import {setSsrWarmupData, getSsrWarmupData} from '../utils/warmupData'
import {EXPERIMENTS} from '../utils/constants'

const CACHE_KEY = 'tr-reservationLocations'
const SSR_WARMUP_KEY = 'tr-reservationLocations-ssr-warmup'

const MAX_REQUESTS_NUMBER = 3

export const fetchPaginatedReservationLocations = async (
  httpClient: IHttpClient,
  isMoreThan100LocationsEnabled: boolean,
): Promise<ReservationLocation[]> => {
  if (!isMoreThan100LocationsEnabled) {
    const req = listReservationLocations({paging: {limit: 100}})
    const res = await httpClient.request(req)

    return res?.data?.reservationLocations?.filter(({archived}) => !archived) ?? []
  }

  let allReservationLocations: ReservationLocation[] = []
  let cursor: CursorPaging['cursor']
  let requestCount = 0
  let hasNextPage = true

  while (hasNextPage && requestCount < MAX_REQUESTS_NUMBER) {
    const response = await httpClient.request(
      listReservationLocations({
        paging: {
          limit: 100,
          cursor,
        },
      }),
    )

    if (!response?.data?.reservationLocations) {
      break
    }

    const currentBatchLocations = response.data.reservationLocations.filter(
      ({archived}) => !archived,
    )
    allReservationLocations = [...allReservationLocations, ...currentBatchLocations]

    cursor = response.data.pagingMetadata?.cursors?.next
    hasNextPage = Boolean(response.data.pagingMetadata?.hasNext)
    requestCount++
  }

  return allReservationLocations
}

const getReservationLocations = async (
  flowAPI: ControllerFlowAPI,
): Promise<ReservationLocation[]> => {
  const {experiments} = flowAPI
  const isMoreThan100LocationsEnabled = experiments?.enabled(EXPERIMENTS.moreThan100Locations)

  const cachedReservationLocations = getDataFromMemoryCache<ReservationLocation[]>(
    flowAPI,
    CACHE_KEY,
  )
  const ssrWarmupData = getSsrWarmupData<ReservationLocation[]>(flowAPI, SSR_WARMUP_KEY)

  // client cache
  if (cachedReservationLocations) {
    return cachedReservationLocations
    // ssr cache
  } else if (ssrWarmupData) {
    return ssrWarmupData
  } else {
    const allReservationLocations = await fetchPaginatedReservationLocations(
      flowAPI.httpClient,
      isMoreThan100LocationsEnabled,
    )

    setDataToMemoryCache(flowAPI, CACHE_KEY, allReservationLocations)
    setSsrWarmupData(flowAPI, SSR_WARMUP_KEY, allReservationLocations)
    return allReservationLocations
  }
}

export const reservationLocationsService = {
  getReservationLocations,
}
